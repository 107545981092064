.hero-section-main-container {
    background-color: #CC0001;
    width: 100%;

}



.header-menu-icon {
    display: none !important;
    margin-bottom: 10px;
    width: 70px;
}

.hero-section-heading-container {
    padding: 100px;
    /* border: 2px solid yellow; */
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.hero-section-heading-container h2, .hero-section-heading-container h1 {
    text-align: center;

}

.hero-section-heading-container h1 {
    text-align: center;
    width: 100%;

}

.hero-section-heading-container h2 {
    color: white;
    font-size: 30px;
    text-transform: uppercase;
    /* border: 2px solid yellow; */

}

.hero-section-heading-container h1 {
    color: white;
    /* border: 2px solid yellow; */
    font-size: 12rem;
    text-transform: uppercase;
}

.home-header {
    width: 100%;
    margin: auto;
    /* border: 1px solid red; */
    padding: 20px 20px 0px 20px;
    background-color: white;
    /* border-radius: 0px 0px 10px 10px; */
    top: 0;
}

.navbar-container {

    display: flex;
    justify-content: flex-end;
    align-items: end;
    /* border: 1px solid red; */
}

.anticon svg {
    display: none !important;
}

.navbar-drawer-item {
    text-decoration: none;
    color: black;
}

.header-logo-container {
    /* border: 1px solid green; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 0px 0px 20px;
}

.header-logo-container img {
    width: 180px;
    height: 100px;
    /* border: 1px solid green; */

}

.header-heading-container {
    text-align: end;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    /* border: 1px solid green; */
    border-radius: 20px;


}

.header-heading-container h1 {
    /* border: 1px solid green; */
    padding: 0px 5px 0px 0px;
    color: #CC0001;
    font-style: bold;
    font-weight: 900;
    line-height: 27px;


}

.navbar-ul {
    display: flex;
    justify-content: center;
    list-style: none;
}

.navbar-menu-item {
    margin: 0px 0px 0px 15px;
    padding: 5px 10px;
    font-size: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* font-weight: 500; */
    /* border: 1px solid green; */

}

.nav-active {

    font-size: 17px;
    font-weight: 800;

}

.navbar-ul li a {
    text-decoration: none;
    color: #040404;
    font-style: normal;
    line-height: 27px;
}



.hero-section-blank-section {
    width: 70%;
    margin: auto;
    border-radius: 10px;
    padding: 60px;
    background-color: white;
    /* margin-bottom: -100px; */
    margin-top: -60px;
}

.home-services-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #FFF;
    /* align-items: center; */

}

.home-services-container {
    display: flex;
    margin-top: 10px;

}







/* card */








.cvyJEp {
    width: 35px !important;
    height: 35px !important;
    font-size: 26px !important;
    background-color: white !important;
    box-shadow: rgb(51, 51, 51) 0px 0px 2px 0px !important;
    color: rgb(51, 51, 51) !important;
    border-radius: 32% !important;
    padding: 0px !important;
    width: 50px !important;
    height: 50px !important;
    min-width: 0px !important;
    line-height: 0px !important;
    margin: 0px 30px !important;
}



/* Outlet css */








/* working hor */
.working-hour table {
    width: 100%;
    margin: auto !important;
    border: 1px solid green;
    border-collapse: collapse;
    margin: 50px;

}

.working-hour table th, .working-hour table td {
    text-align: center;
    border: 1px solid black;
    border-collapse: collapse;
    width: 100px;
    padding: 8px 0px;

}

/* customer Review */
.customer-review-subheading {
    text-align: center;
    font-size: 22px;
    font-weight: 700;
}

/* customer review card */
.customer-review-carousel-card-main-container {
    margin: 50px 0px;
    background-color: rgba(17, 17, 17, 0.05) !important;
    padding: 24px;

}

.customer-review-carousel-card-img {
    width: 44px !important;
    height: 44px;
    border-radius: 100px;
    margin: auto;
}

.customer-review-carousel-card-start-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row !important;
    padding: 5px 0px 0px 0px;
}

.customer-review-carousel-card-start-container .customer-review-carousel-card-start-img {
    width: 16px;
    height: 16px;
}

.customer-review-carousel-card-name {
    text-align: center;
    font-weight: 700;
}

.customer-review-carousel-card-rating {


    font-weight: 400 !important;
    font-size: 11px !important;
    color: #757575;
    text-align: center !important;
}

.customer-review-carousel-card-review-text {
    text-align: center !important;
    font-size: 19px !important;
    font-weight: 100 !important;
}

.customer-review-carousel-card-google-container {
    padding: -20px 0px -10px 0px;
    /* border: 1px solid red; */
    margin: auto;
    display: flex;
    width: 80%;

}



.customer-review-carousel-card-googleimg {
    width: 40px;
    height: 40px;
}

.customer-review-carousel-card-google-text {
    text-align: start !important;
    color: red;
    font-size: 13px !important;
    font-weight: 400;

}

.customer-review-carousel-card-postingby {
    text-align: start !important;
}

/* about section */
.about-section-main-container {
    width: 100%;
    margin: auto;
    border: 1px solid red;
}

.about-section-main-content-wrapper {
    display: flex;
    padding: 50px;
}

.about-section-content-wrapper {
    width: 100%;
    display: flex;
    padding: 0px 30px;
    flex-direction: column;
    align-items: center;
    /* border: 1px solid red; */
}

.about-section-content-wrapper .about-section-content-para1 {
    width: 100%;
}

.about-section-content-wrapper>* {
    margin: 20px 0px;
}

.about-section-bottom-content-wrapper {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-end;
    /* border: 1px solid red; */
    width: 100%;
}

.about-section-bottom-content-para1 {
    color: red;
    font-style: Segoe;
    font-weight: 600;
    font-size: 36px;
    line-height: 43.2px;
    text-align: end;
}

.about-section-bottom-content-para1 {
    font-size: 24p;
}

.about-section-content-image {
    width: 100%;
}


/*Slick button*/

.slick-next:before, .slick-prev:before {
    font-size: 30px;
    line-height: 0;
    color: #ffffff;
    background-color: black;
    border-radius: 50%;
}

/* Gallery */
.gallery-corousel-main-container {
    padding: 50px;
}



.gallery-carousel-item {
    width: 100%;
}

/* Responsive Screen */



/* Medium devices (landscape tablets, 768px and up) */


@media (max-width: 375px) {
    .header-heading-container h1 {
        font-size: 20px !important;
    }
}
@media (max-width: 420px) {
    .header-heading-container h1 {
        font-size: 20px !important;
    }
}

@media (max-width: 476px) {
    .navbar-ul>* {
        padding: 5px 10px;
        font-size: 10px;
    }

    .header-heading-container h1 {
        text-align: center !important;
        font-style: normal !important;
        font-weight: 700 !important;
        line-height: 27px !important;
    }
}

@media (max-width: 576px) {
    .navbar-ul>* {
        margin: 0px 0px 0px -7px;
        padding: 5px 8px;
        font-size: 13px;
        font-weight: 600;
        /* border: 1px solid green; */
    }

    .header-logo-container img {
        width: 70px;
        height: 70px;
        /* border: 1px solid green; */

    }

    .header-heading-container h1 {
        font-size: 23px;

        font-style: normal;
        font-weight: 700;
        line-height: 27px;
        /* identical to box height, or 14% */



    }

    .hero-section-blank-section {
        width: 70%;
        padding: 60px;
        margin-top: -60px;
    }

    .hero-section-heading-container h1 {
        font-size: 3rem !important;
    }

    .hero-section-heading-container h2 {
        font-size: 23px;
        /* border: 2px solid yellow; */
    }

    .hero-section-heading-container {
        padding: 60px !important;
    }


    .home-services-item {
        padding: 30px;

    }



    .home-services-item h4 {
        font-size: 15px;
    }

    .home-services-item h2 {
        font-size: 17px;
    }

    .about-section-main-content-wrapper {
        padding: 10px !important;
    }

    .about-section-bottom-content-para1 {
        font-size: 20px;
    }

    .about-section-bottom-content-para2 {
        font-size: 17px;

    }

    .about-section-content-wrapper {
        width: 100%;
        display: flex;
        padding: 0px 12px;
        flex-direction: column;
        align-items: center;
        /* border: 1px solid red; */
    }

    .card-image {
        width: 150px;
        height: 80px;
    }



    .outlet-main-heading {
        font-size: 26px;
    }

    .outlet-main-heading-img {
        width: 50%;
        align-self: center;
        margin: 0px 25%;
        /* border: 1px solid red; */
    }

    .working-hour table {
        margin: 20px;
    }

    .carousel-main-container {
        padding: 10px;
    }

    .carousel-main-container h1 {
        margin: 20px 0px 20px 60px;

    }

    .css-mhc70k-MuiGrid-root>.MuiGrid-item {
        padding-top: 0px !important;
    }
}

@media (max-width: 768px) {
    .navbar-ul {
        display: none !important;
    }

    .header-menu-icon {
        display: inline-block !important;
    }

    .navbar-ul-drawer {
        display: flex;
        justify-content: center;
        flex-direction: column;
        list-style: none;
    }

    .navbar-ul-drawer>* {
        padding: 5px 5px;
        font-size: 17px;
        text-decoration: none;
    }

    .navbar-ul-drawer ul li a {
        text-decoration: none;
        color: red;

    }


    .ant-drawer-body {

        padding: 10px !important;
        overflow: auto;
    }


    .hero-section-heading-container h1 {
        font-size: 4rem !important;
    }

    hero-section-heading-container h2 {
        font-size: 20px;
    }

    .hero-section-heading-container {
        padding: 60px !important;
    }


    .home-services-item {
        padding: 16px;

    }



    .home-services-item h4 {
        font-size: 20px;
    }

    .home-services-item h2 {
        font-size: 23px;
    }

    .about-section-main-content-wrapper {
        padding: 10px !important;
    }

    .about-section-bottom-content-para1 {
        font-size: 20px;
    }

    .about-section-bottom-content-para2 {
        font-size: 17px;

    }

    .card-wrapper {
        width: 280px;
        height: 328px;
    }

    .card-image {
        width: 150px;
        height: 80px;
    }



    .outlet-main-heading {
        font-size: 26px;
    }

    .outlet-main-heading-img {
        width: 50%;
        align-self: center;
        margin: 0px 25%;
        /* border: 1px solid red; */
    }

    .working-hour table {
        margin: 20px;
    }

    .carousel-main-container {
        padding: 10px;
    }

    .carousel-main-container h1 {
        margin: 20px 0px 20px 60px;

    }

    .css-mhc70k-MuiGrid-root>.MuiGrid-item {
        padding-top: 0px !important;
    }


}

/* Large devices (laptops/desktops, 992px and up) */
@media (max-width: 992px) {
    .hero-section-heading-container h1 {
        font-size: 8rem !important;
    }
}

@media (max-width: 860px) {
    .hero-section-heading-container h1 {
        font-size: 4rem !important;
    }
}