.ServiceSchedule-table-container {
    background-color: #f9f9f9;
    width: 100%;
    padding: 50px 0px;
}

.ServiceSchedule-heading {
    text-align: center;
    font-weight: 900;
    color: #CC0001;
}

.serviceSchedule-table-heading {
    padding-left: 200px;
    padding-top: 20px;
    padding-bottom: 20px;
    font-weight: 900;
    font-style: bold;
}

.table2 {
    width: 70%;
    margin: auto;


}

.table2 th,
.table2 td {
    width: 210px;
    text-align: center;
    padding: 5px 0px;
}

.table2,
.table2 th,
.table2 td {
    border: 0.5px solid rgb(188, 187, 187);
    border-collapse: collapse;
}

@media screen and (max-width:991px) {
    .table2 {
        width: 95%;
        margin: auto;


    }

}

@media screen and (max-width:768px) {
    .serviceSchedule-table-heading {
        padding-left: 50px;
        padding-top: 20px;
        padding-bottom: 20px;

    }

}

@media screen and (max-width:576px) {



    .table2,
    .table2 th,
    .table2 td {
        font-size: 13px;
    }

}

@media screen and (max-width:420px) {

    .table2,
    .table2 th,
    .table2 td {
        font-size: 10px;
    }

}