.footer-main-container {
    height: 200px;
}

.footer-content-container {
    padding: 40px 0px;
    display: flex !important;
    justify-content: space-between !important;
    align-items: center;
    margin: auto;
    flex-direction: column;
    background: red;

}

.footer-content-container a {
    text-decoration: none;
    color: white;
    font-size: 17px;
}

.footer-vertical-hr {
    display: inline-block;
    background-color: white;
    width: 2px !important;
    height: 15px !important;
    margin: 0px 10px;
}

.footer-content-container h6 {
    margin-top: 80px;
    color: white;
}

.footer-book-ride-contact {
    font-weight: 900;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 0px;
}

@media screen and (max-width:576px) {
    .footer-content-container {
        padding: 5px 0px;


    }

    .footer-content-container h6 {
        text-align: center;
    }

    .footer-vertical-hr {

        height: 9px !important;
        margin: 0px 5px;
    }

    .footer-content-container a {

        font-size: 14px;
    }

    .footer-book-ride-contact {
        font-size: 20px;
    }

}

@media screen and (max-width:476px) {
    .footer-content-container {
        padding: 5px 0px;


    }

    .footer-content-container h6 {
        text-align: center;
    }

    .footer-vertical-hr {

        height: 9px !important;
        margin: 0px 5px;
    }

    .footer-content-container a {

        font-size: 11px;
    }

    .footer-book-ride-contact {
        font-size: 20px;
    }

}

@media screen and (max-width:375px) {
    .footer-content-container {
        padding: 5px 0px;


    }

    .footer-content-container h6 {
        text-align: center;
        font-size: 14px;
    }

    .footer-vertical-hr {

        height: 9px !important;
        margin: 0px 5px;
    }

    .footer-content-container a {

        font-size: 9px;
    }

    .footer-book-ride-contact {
        font-size: 20px;
        margin: 0px;
    }

    .footer-contact-call-container {
        padding: 0px 10px;

    }

    .footer-vertical-hr {
        margin: 0px 12px !important;
    }
}