.finance-container {
    width: 100%;
    margin: auto;
    padding: 20px;
    /* background-color: #CC0001; */
}

.finance-item {
    /* width: 150px;
    height: 150px; */
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    padding: 20px;
}

.fianance-img {
    width: 150px !important;
    height: 150px !important;
}

.heading {
    margin: 0px 0px 30px 0px;
    color: #CC0001;
    font-size: 33px;
    font-weight: 900;
    font-style: bold;
    text-align: center;

}

.finance-container .rec-carousel {
    height: 205px !important;
}



.finance-corousel .jZpChr {
    width: 310px !important;
}

@media screen and (max-width:576px) {
    .finance-corousel .jZpChr {
        width: 420px !important;
    }

}

@media screen and (max-width:476px) {
    .finance-corousel .jZpChr {
        width: 320px !important;
    }

}

@media screen and (max-width:420px) {
    .finance-corousel .jZpChr {
        width: 265px !important;
    }

}