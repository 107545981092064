.social-icons.ant-float-btn-group-square .ant-float-btn-square:not(:last-child) {
    background-color: black;
    width: 45px !important;
}

.social-icons .ant-float-btn-default .ant-float-btn-body {
    background-color: black;
}

.social-icons .ant-float-btn-group-square .ant-float-btn-square:not(:last-child) {
    background-color: none !important;
    width: 45px !important;

}

.social-icons.ant-float-btn-group-square .ant-float-btn-square:last-child {
    width: 45px;
}

.social-icons .ant-float-btn .ant-float-btn-body .ant-float-btn-content {
    padding: 2px 9px !important;
}