.details-main-container {
    padding: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.detail-left-section {
    width: 100%;
    height: 100%;
    /* border: 1px solid red; */
}

.detail-left-heading-section,
.details-left-image-container {
    padding: 5px 50px;
}

.detail-left-heading-section .vehicle-name {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: 900;
    /* font-size: 20px; */
}

.detail-left-heading-section h1 .info-icon {
    font-weight: 800;
    font-size: 30px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0px 5px;

}

.detail-left-heading-section .vehicle-price {
    font-size: 32px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.detail-left-heading-section .terms-and-condition-heading {
    margin-left: 8px;
    font-size: 15px;
}

.details-left-image-container {
    /* border: 1px solid red; */
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.details-left-image-container img {
    width: 100%;
    height: 100%;
}

.color-option-heading {
    font-weight: 500;
    padding-left: 40px;
    margin-top: 1rem;
}

.details-carousel-card {
    height: 100px;
    width: 350px;
    margin: 10px auto;
    /* border: 1px solid red; */
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);


}

.card-img-container {
    width: 100%;
    height: 100%;
    padding: 20px;
    /* border: 1px solid red; */

}

.card-detail-container {
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
    width: 100%;
    padding: 10px;
    /* border: 1px solid red; */
}

.card-img-container img {
    width: 100%;
    height: 100%;
}

.detail-carousel-mySwiper {
    padding: 0px 50px;
    position: relative;
}

.detail-carousel-mySwiper .swiper-button-prev:after {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px !important;
    height: 40px !important;
    padding: 15px;
    font-size: 20px !important;
    font-weight: 800;
    background-color: white !important;
    color: black !important;
    border: 1px solid black;
    border-radius: 25px;
    background: white;

}

.detail-carousel-mySwiper .swiper-button-next:after {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px !important;
    height: 40px !important;
    padding: 15px;
    font-size: 20px !important;
    font-weight: 800;
    background-color: white !important;
    color: black !important;
    border: 1px solid black;
    border-radius: 25px;
    background: white;
}

.detail-carousel-mySwiper .swiper-slide {
    width: 100% !important;
}

.detail-carousel-mySwiper .swiper,
swiper-container {
    width: 684px;
}

/* btn css */
.detail-right-btn-container {
    /* border: 1px solid red; */
}

.detail-right-section {
    overflow: scroll;
    height: 500px;
    width: 100%;
    padding: 50px 0px;


}

.detail-right-section::-webkit-scrollbar {
    display: none;
}

.spectfication-btn {
    width: 95%;
    margin: auto !important;
    color: white;
    background-color: black;
    border-radius: 5px;
    padding: 10px 0px;
    font-size: 16px;
}

.spectfication-feature-btn {
    width: 50%;
    margin: auto !important;
    color: white;
    background-color: black;
    border-radius: 5px;
    padding: 8px 0px;
    font-size: 16px;
}

.spectfication-btn-active {
    width: 95%;
    margin: auto !important;
    border-radius: 5px;
    padding: 12px 0px;
    font-size: 16px;
    color: white;
    background-color: red;
    border: none;
}

.spectfication-feature-btn-active {
    width: 50%;
    margin: auto !important;
    border-radius: 5px;
    padding: 8px 0px;
    font-size: 11px;
    color: white;
    background-color: red;
    border: none;
}

.detail-right-bottom-btn-container {
    padding: 0px 10px;
    display: flex;
    justify-content: flex-end;
    align-items: end;
}

.detail-right-bottom-btn-container .spectfication-bottom-btn {
    width: 185px;
    color: white;
    background-color: black;
    border-radius: 5px;
    padding: 8px 0px;
    font-size: 15px;
    /* margin-left: 50px; */

}

/* //table css */
.tableClass {
    width: 80%;
    margin: 50px auto;
}

.table-heading {


    text-align: center;
    color: white;
    background-color: #cc0001;
}

.tableClass td {
    padding: 10px !important;
}

.tableClass tr,
.tableClass td {
    height: 30px;
    padding: 5px 0px;
    border: 1px solid white;
    border-collapse: collapse;
    background-color: black;
    color: white;
}

.alternater-table-tr {
    background-color: gray;
}

.detail-right-brochure-btn-container {
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: end;
}

.detail-right-brochure-btn-container button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: #fcf0f0;
    font-size: 19px;
}

.download-icon {
    font-size: 25px !important;
    margin: 0px 7px;
}

.ant-popover .ant-popover-content {
    width: 250px !important;
    border-radius: 10px !important;

}

.ant-popover .ant-popover-inner {
    background-color: black;
    color: white;
    background-clip: padding-box;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    padding: 12px;
}

.ant-popover .ant-popover-arrow::after {
    background-color: black !important;
}

.pop-over-data {
    color: white;
    font-size: 13px;
    font-weight: 300;
}

@media only screen and (max-width: 991px) {
    .detail-right-brochure-btn-container {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media only screen and (max-width: 576px) {
    .details-main-container {
        padding: 50px 15px;

    }

    .color-option-heading {
        padding-left: 0px;
    }

    .detail-right-bottom-btn-container {
        padding: 0px 0px;
        width: 95% !important;
    }

    .spectfication-feature-btn, .spectfication-btn, .spectfication-btn-active, .spectfication-feature-btn-active {
        width: 100%;

    }

    .detail-left-heading-section,
    .details-left-image-container {
        padding: 5px 10px;
    }

    .detail-left-heading-section .vehicle-price {
        font-size: 22px;

    }

    .detail-carousel-mySwiper {
        padding: 0px 0px;
        width: 100%;
        position: relative;
    }


}

@media only screen and (max-width: 476px) {
    .detail-left-heading-section .vehicle-price {
        font-size: 13px;

    }
}

@media only screen and (max-width: 420px) {
    .details-carousel-card {
        height: 100px;
        width: 280px;
        margin: 10px auto;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }

    .card-img-container {
        padding: 5px;
    }

    .spectfication-feature-btn-active, .spectfication-btn, .spectfication-btn-active, .spectfication-feature-btn {
        width: 100%;
        padding: 10px 0px;
        font-size: 14px;
    }

    .detail-left-heading-section .vehicle-price {
        font-size: 14px;
    }

    .detail-left-heading-section .terms-and-condition-heading {
        margin-left: 3px;
        font-size: 11px;
    }
}

@media only screen and (max-width: 376px) {
    .detail-carousel-mySwiper .swiper-button-prev:after {

        width: 22px !important;
        height: 22px !important;
        padding: 12px;
        font-size: 14px !important;


    }

    .detail-carousel-mySwiper .swiper-button-next:after {

        width: 22px !important;
        height: 22px !important;
        padding: 12px;
        font-size: 14px !important;

    }

    .footer-book-ride-contact {
        font-size: 17px;
        margin: 0px;
    }

    .footer-content-container a {
        font-size: 16px;
    }
}