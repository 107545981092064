/* gallery carousel */
.gallery-carousel-item {
    width: 100%;
}

.gallery-corousel-heading {
    padding: 20px 0px;
    color: #CC0001;
    font-style: normal;
    font-weight: 700;
    line-height: 27px;
    margin: 20px 0px 40px 80px !important;
}

.gallery-Swiper .swipper-button-prev {
    padding: 15px !important;
    margin: 20px !important;
}

.hero-Swiper .swiper-button-next:after, .swiper-button-prev:after {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px !important;
    height: 40px !important;
    padding: 15px;
    font-size: 20px !important;
    font-weight: 800;
    background-color: transparent !important;
    color: black !important;
    border: none;
    border-radius: 10px;
    background: white;
}

.hero-Swiper .swiper-button-next:after, .swiper-button-next:after {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px !important;
    height: 40px !important;
    padding: 15px;
    font-size: 20px !important;
    font-weight: 800;
    background-color: transparent !important;
    color: black !important;
    border: none;
    border-radius: 10px;
    background: white;

}

.bottom-hero-gallery-section {
    padding: 10px 0px;
    background-color: #CC0001;
}

.bottom-hero-gallery-container {
    margin: auto;
    width: max-content;
}

.bottom-hero-gallery-container a {
    text-decoration: none;
    color: white;
    font-weight: 700;
    font-style: bold;
}

.bottom-hero-gallery-container a:visited {
    color: white;
}

@media screen and (max-width:768px) {
    .gallery-corousel-heading {
        padding: 20px 0px;
        text-align: center;
        margin: 10px 0px !important;
    }
}