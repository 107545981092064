.navbar-main-container {
    padding: 30px;
}

.hero-section-main-container {
    background-color: #CC0001;
    width: 100%;

}

.header-menu-icon {
    display: none !important;
    margin-bottom: 10px;
    width: 70px;
}

.hero-section-heading-container {
    padding: 100px;
    /* border: 2px solid yellow; */
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.hero-section-heading-container h2,
.hero-section-heading-container h1 {
    text-align: center;

}

.hero-section-heading-container h1 {
    text-align: center;
    width: 100%;

}

.hero-section-heading-container h2 {
    color: white;
    font-size: 30px;
    text-transform: uppercase;
    /* border: 2px solid yellow; */

}

.hero-section-heading-container h1 {
    color: white;
    /* border: 2px solid yellow; */
    font-size: 12rem;
    text-transform: uppercase;
}

.home-header {
    width: 100%;
    margin: auto;
    /* border: 1px solid red; */
    padding: 20px 20px 0px 20px;
    background-color: white;
    /* border-radius: 0px 0px 10px 10px; */
    top: 0;
}



.navbar-drawer-item {
    text-decoration: none;
    color: black;
}



.header-logo-container img {
    width: 100px;
    height: 100px;
    /* border: 1px solid green; */

}

.header-heading-container {
    text-align: end;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    /* border: 1px solid green; */
    border-radius: 20px;


}

.header-heading-container h1 {
    /* border: 1px solid green; */
    padding: 0px 5px 0px 0px;
    color: #CC0001;


}





.hero-section-blank-section {
    width: 70%;
    margin: auto;
    border-radius: 10px;
    padding: 60px;
    background-color: white;
    /* margin-bottom: -100px; */
    margin-top: -60px;
}

.home-services-section {
    /* width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #FFF2F2;
    align-items: center; */

}

.home-services-container {
    display: flex;
    margin-top: 10px;

}

.home-services-item {
    display: flex;
    justify-content: center;
    padding: 50px 20px;
}

.services-item-content-container {
    width: 200px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 0px 15px;
    /* width: 100%; */
}

.services-item-content-container h2 {

    font-style: normal;
    font-weight: 700;
    line-height: 27px;
    color: #CC0001;

}

.services-item-content-container h3 {
    display: flex;
    flex-direction: row;
    font-size: 19px;
    font-style: normal;
    font-weight: 500;
    line-height: 27px;
}

.services-item-content-container h3 span {
    display: flex;
    margin: 0px 2px;
}

.home-services-item img {
    margin: auto;
    width: 90px !important;
    height: 90px !important;
}

.home-services-container>* {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

}

/* card */
.hnvVol {

    height: 400px !important;
}


.card-wrapper {
    width: 270px;
    height: 270px;
    box-shadow: 0px 1px 1px 0px gray;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column !important;
    margin: 15px;
    background-color: white;

}



.card-wrapper>* {
    margin: 14px 0px;
}

.card-wrapper:hover {
    z-index: 10;
    /* width: 250px; */

}

.card-image-wrapper {
    display: flex;
    justify-content: center;
    align-items: center !important;
    /* border: 1px solid red; */

}

.card-image {
    width: 200px;
    height: 120px;
}

.card-text-wrapper>* {
    margin-top: 30px;
    text-align: center;
}

.card-text-wrapper h6 {
    font-size: 1.2rem;
    font-weight: 600;
}




.cvyJEp {
    width: 35px !important;
    height: 35px !important;
    font-size: 24px !important;
    background-color: white !important;
    box-shadow: rgb(51, 51, 51) 0px 0px 2px 0px !important;
    color: rgb(51, 51, 51) !important;
    border-radius: 11% !important;
    padding: 0px !important;
    width: 30px !important;
    height: 30px !important;
    min-width: 0px !important;
    line-height: 0px !important;
    margin: 0px 30px !important;
}

.dkjKVr {
    width: 35px !important;
    height: 35px !important;

    font-size: 26px !important;
    background-color: white !important;
    box-shadow: rgb(51, 51, 51) 0px 0px 2px 0px !important;
    border-radius: 32% !important;
    width: 50px !important;
    height: 50px !important;
    min-width: 0px !important;
    line-height: 0px !important;
    margin: 0px 30px !important;

}

.scooter-main .rec-carousel {
    height: 345px !important;
}

.carousel-main-container {
    width: 100%;
    margin: auto;
    padding: 50px 0px;
    background-color: #CC0001;
}

.outlet-carousel-main-container {
    width: 100%;
    margin: auto;
    padding: 25px 20px;
    background-color: white;
}

.outlet-section-main-heading {
    padding: 20px 0px;
    color: #CC0001;
    font-style: normal;
    font-weight: 700;
    line-height: 27px;
    margin: 20px 0px 40px 110px !important;

}

.iDBcKo {
    width: 93% !important;
    margin: 0px auto !important;
}

.iDBcKo {

    margin: -1px -40px !important;
}

.motorcycle-carousel-main-container {
    width: 100% !important;
    background-color: #f9f9f9;
}

.carousel-main-container h1 {
    color: white !important;




}

.scooter-section-heading {
    Width: 212px;
    Height: 27px;
    font-style: bold;
    font-weight: 900;
    line-height: 27px;
    color: white !important;
    margin: 20px 0px 40px 130px !important;
}

.motorcycle-carousel-main-container .motorcycle-carousel-heading {
    Width: 212px;
    Height: 27px;
    font-style: bold;
    font-weight: 900;
    line-height: 27px;
    color: #CC0001 !important;
    margin: 20px 0px 40px 130px !important;

}

.carousel-main {
    height: 100%;

    /* border: 1px solid red; */
}

/* Outlet css */

.outlet-carousel-main {
    height: 260px !important;
}


.outlet-main-heading {
    text-align: center;
    color: #CC0001;
}

.outlet-hr-container img {
    width: 20px;
    height: 20px;
}

.outlet-hr-container>* {
    margin: 8px;
}

.outlet-hr {
    width: 100px;
    height: 3px;
    background: rgb(127, 49, 49);
}

.outlet-hr-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.outlets-grid-wrapper {
    padding: 50px 80px;

}

.outlets-grid-item {
    padding: 20px 10px;
    display: flex !important;
    justify-content: space-between !important;
    flex-direction: column !important;
    width: 245px !important;
    height: 260px !important;

}

.outlets-grid-item h1 {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: red;
    margin-top: 80px;
    font-size: 3rem;
}

.outlets-grid-item>* {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    font-weight: 900;
    font-style: bold;

}

.outlets-grid-item p,
.outlets-grid-item h4 {
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 400;
}

.outlets-grid-item h4 {
    font-weight: 700;
}

.outlets-grid-item-image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 153px;
    height: 100px;
    margin: auto;
    border-radius: 10px;


}

.outlet-main-heading-img {
    width: 80%;
    align-self: center;
    margin: 0px 10%;
    /* border: 1px solid red; */
}



/* customer Review */
.customer-review-subheading {
    text-align: center;
    font-size: 22px;
    font-weight: 700;
}

/* customer review card */
.customer-review-carousel-card-main-container {
    margin: 50px 0px;
    background-color: rgba(17, 17, 17, 0.05) !important;
    padding: 24px;

}

.customer-review-carousel-card-img {
    width: 44px !important;
    height: 44px;
    border-radius: 100px;
    margin: auto;
}

.customer-review-carousel-card-start-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row !important;
    padding: 5px 0px 0px 0px;
}

.customer-review-carousel-card-start-container .customer-review-carousel-card-start-img {
    width: 16px;
    height: 16px;
}

.customer-review-carousel-card-name {
    text-align: center;
    font-weight: 700;
}

.customer-review-carousel-card-rating {


    font-weight: 400 !important;
    font-size: 11px !important;
    color: #757575;
    text-align: center !important;
}

.customer-review-carousel-card-review-text {
    text-align: center !important;
    font-size: 19px !important;
    font-weight: 100 !important;
}

.customer-review-carousel-card-google-container {
    padding: -20px 0px -10px 0px;
    /* border: 1px solid red; */
    margin: auto;
    display: flex;
    width: 80%;

}



.customer-review-carousel-card-googleimg {
    width: 40px;
    height: 40px;
}

.customer-review-carousel-card-google-text {
    text-align: start !important;
    color: red;
    font-size: 13px !important;
    font-weight: 400;

}

.customer-review-carousel-card-postingby {
    text-align: start !important;
}

/* about section */
.about-main-container {
    padding: 25px 25px;
}

.about-left-container {
    padding: 0px 25px;
}

.about-left-container h1 {
    margin-bottom: 40px;
    color: #CC0001;
    font-style: bold;
    font-weight: 900;
    /* font-size: 128px; */
    line-height: 27px;
}

.about-left-container p {
    font-style: normal;
    font-weight: 500;
}

.about-right-container {
    padding: 0px 25px;
}

.about-bottom-container {
    display: flex;
    justify-content: flex-start;
}

.about-contacts,
.about-experience {
    padding: 25px 15px;
}

.about-contacts h1,
.about-experience h1 {
    font-style: bold;
    font-weight: 900;
    line-height: 27px;
    color: #CC0001;
}

.about-contacts h2,
.about-experience h2 {
    font-style: bold;
    font-weight: 900;
    line-height: 27px;
    color: black;
    font-size: 23px;
}

.about-section-content-image {
    border-radius: 10px;
    height: 32rem;
}

/* Gallery */

.gallery-carousel-item {
    width: 100%;
}

/* Responsive Screen */



/* Medium devices (landscape tablets, 768px and up) */


@media (max-width: 476px) {
    .navbar-ul>* {
        padding: 5px 10px;
        font-size: 10px;
    }
}

@media (max-width: 576px) {
    .navbar-ul>* {
        margin: 0px 0px 0px -7px;
        padding: 5px 8px;
        font-size: 13px;
        font-weight: 600;
        /* border: 1px solid green; */
    }

    .header-logo-container img {
        width: 70px;
        height: 70px;
        /* border: 1px solid green; */

    }

    .header-heading-container h1 {
        font-size: 23px;

    }

    .hero-section-blank-section {
        width: 70%;
        padding: 60px;
        margin-top: -60px;
    }

    .hero-section-heading-container h1 {
        font-size: 3rem !important;
    }

    .hero-section-heading-container h2 {
        font-size: 23px;
        /* border: 2px solid yellow; */
    }

    .hero-section-heading-container {
        padding: 60px !important;
    }


    .home-services-item {
        padding: 30px;

    }



    .home-services-item h4 {
        font-style: normal;
        font-weight: 700;
        font-size: 96px;
        line-height: 27px;
        font-size: 15px;
        color: #CC0001;
    }


    .home-services-item h2 {
        font-size: 17px;
    }

    .about-section-main-content-wrapper {
        padding: 10px !important;
    }

    .about-section-bottom-content-para1 {
        font-size: 20px;
    }

    .about-section-bottom-content-para2 {
        font-size: 17px;

    }

    .about-section-content-wrapper {
        width: 100%;
        display: flex;
        padding: 0px 12px;
        flex-direction: column;
        align-items: center;
        /* border: 1px solid red; */
    }

    .card-image {
        width: 150px;
        height: 80px;
    }





    .working-hour table {
        margin: 20px;
    }

    .carousel-main-container {
        padding: 10px;
    }

    .carousel-main-container h1 {
        margin: 20px 0px 20px 60px;

    }

    .css-mhc70k-MuiGrid-root>.MuiGrid-item {
        padding-top: 0px !important;
    }
}

@media (max-width: 768px) {
    .navbar-ul {
        display: none !important;
    }

    .header-menu-icon {
        display: inline-block !important;
    }

    .navbar-ul-drawer {
        display: flex;
        justify-content: center;
        flex-direction: column;
        list-style: none;
    }

    .navbar-ul-drawer>* {
        padding: 5px 5px;
        font-size: 17px;
        text-decoration: none;
    }

    .navbar-ul-drawer ul li a {
        text-decoration: none;
        color: red;

    }


    .ant-drawer-body {

        padding: 10px !important;
        overflow: auto;
    }


    .hero-section-heading-container h1 {
        font-size: 4rem !important;
    }

    hero-section-heading-container h2 {
        font-size: 20px;
    }

    .hero-section-heading-container {
        padding: 60px !important;
    }


    .home-services-item {
        padding: 16px;

    }



    .home-services-item h4 {
        font-size: 20px;
    }

    .home-services-item h2 {
        font-size: 23px;
    }

    .about-section-main-content-wrapper {
        padding: 10px !important;
    }

    .about-section-bottom-content-para1 {
        font-size: 20px;
    }

    .about-section-bottom-content-para2 {
        font-size: 17px;

    }

    .card-wrapper {
        width: 280px;
        height: 328px;
    }

    .card-image {
        width: 150px;
        height: 80px;
    }



    .outlet-main-heading {
        font-size: 26px;
    }


    .motorcycle-carousel-main-container .motorcycle-carousel-heading {
        text-align: center;
        margin: 10px 0px !important;
        width: 100%;
        padding: 20px 0px;


    }

    .working-hour table {
        margin: 20px;
    }

    .carousel-main-container {
        padding: 10px;
    }

    .carousel-main-container h1 {
        margin: 10px 0px !important;
        text-align: center;
        width: 100%;
        padding: 20px 0px;


    }

    .outlet-section-main-heading {
        padding: 20px 0px;
        text-align: center;
        margin: 10px 0px !important;
    }

    .css-mhc70k-MuiGrid-root>.MuiGrid-item {
        padding-top: 0px !important;
    }
}

/* Large devices (laptops/desktops, 992px and up) */

@media screen and (max-width: 991px) {
    .hero-section-heading-container h1 {
        font-size: 8rem !important;
    }

    .services-item-content-container h3 {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;

    }
}

@media (max-width: 860px) {
    .hero-section-heading-container h1 {
        font-size: 4rem !important;
    }
}