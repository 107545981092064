.contact-container {
    padding: 20px;
    background: #f2f2f2;
}

.contact-heading {
    font-style: normal;
    font-weight: 700;
    line-height: 27px;
    /* or 21% */

    text-align: center;

    color: #CC0001;
}

.contact-left-container {
    padding: 20px;
}

.contact-left-inner-container {
    padding: 20px;
    margin: auto;
    width: 70%;
    /* height: 80%; */
    /* border: 1px solid red; */
    background-color: white;

}

.contact-left-inner-container-heading {
    color: #cc0001;
    text-align: left;
    margin-bottom: 20px !important;
    font-weight: 900;
    font-style: bold;
}

.contact-left-inner-container-item-label {
    display: inline;
    margin: 5px 0px;
    font-weight: 900;
    font-style: bold;
}

.contact-left-inner-container-item-details {
    font-weight: 400;
    word-spacing: 2px;
    margin: 5px 5px;
    padding: 0px 5px;

}

.contact-right-inner-container {
    padding: 20px;

}

.half-size-input {
    width: 90%;
    border: 1px solid rgba(255, 0, 0, 0.5);
    padding: 4px 8px;
    margin-bottom: 20px;


}

.full-size-input {
    border: 1px solid rgba(255, 0, 0, 0.5);
    width: 100%;
    padding: 4px 8px;
    margin-bottom: 20px;

}

.contact-submit-btn {
    width: 40%;
    margin: auto;
    background-color: #cc0001;
    color: white;
    border: none;
    padding: 10px 50px !important;
    border-radius: 5px;
    font-weight: 600;
    font-size: 17px;
}


@media only screen and (max-width: 991px) {
    .contact-left-inner-container {

        width: 100%;
    }

}

@media only screen and (max-width: 420px) {
    .contact-submit-btn {
       
        padding: 10px 10px !important;
        
        font-size: 17px;
    }

}