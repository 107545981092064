/* .App {
    margin-top: 2%;
} */

.css-hip9hq-MuiPaper-root-MuiAppBar-root {
    background-color: #CC0001 !important;
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    scroll-behavior: smooth;
}

html {
    scroll-behavior: smooth;
}